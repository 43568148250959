import { string } from 'helpers/primitives'


const truncateText = (text: string, length: number) => {
  let result = string.multiLineToOneLine(text)

  if (result.length < length) {
    return result
  }

  // Trim to length without breaking words apart
  result = result.replace(new RegExp(`^(.{${length}}[\\S]*).*`), '$1')
  // Add dots symbol
  result = `${result}…`

  return result
}


export default truncateText
