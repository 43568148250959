import xss, { type IFilterXSSOptions } from 'xss'


const NEW_LINES_ONLY_OPTIONS: IFilterXSSOptions = {
  whiteList: {
    br: [],
  },
  stripIgnoreTag: false,
}

const SAFE_ATTRIBUTES = [ 'class', 'data-testid', 'style' ]

const SAFE_HTML_OPTIONS: IFilterXSSOptions = {
  whiteList: {
    br: [ 'class' ],
    b: SAFE_ATTRIBUTES,
    i: SAFE_ATTRIBUTES,
    strong: SAFE_ATTRIBUTES,
    span: SAFE_ATTRIBUTES,
    div: SAFE_ATTRIBUTES,
  },
  stripIgnoreTag: false,
}

export const sanitizeHtmlRemoveAll = (source: string): string => {
  return xss(source, {
    whiteList: {},
    stripIgnoreTag: true,
  })
}

export const sanitizeHtmlStrict = (source: string): string => {
  return xss(source, NEW_LINES_ONLY_OPTIONS)
}

export const sanitizeHtmlSafe = (source: string): string => {
  return xss(source, SAFE_HTML_OPTIONS)
}

// ATTN we should not provide custom options for better refactoring in future
// export const sanitizeHtml = (source: string, options: IFilterXSSOptions = NEW_LINES_ONLY_OPTIONS): string => {
//   return xss(source, options)
// }

