/**
 * Masks are used to format user input in fields like phone number, date, etc.
 */
export type Mask = (value: string) => string

export const createMask = (formula: string): Mask => {
  const patternArray = formula.split('')

  return (value) => {
    if (!value) {
      return ''
    }

    let result = '', index = 0

    for (let i = 0; i < patternArray.length; i++) {
      if (index >= value.length) {
        break
      }

      const symbol = patternArray[i]

      if (symbol === 'X' || symbol === value[index]) {
        result += value[index++]
      }
      else {
        result += symbol
      }
    }

    return result
  }
}

type CreateMaskWithModifiers = (params: {
  mask: string
  preModify?: (value: string) => string
  postModify?: (value: string) => string
}) => (value: string) => string

export const createMaskWithModifiers: CreateMaskWithModifiers = ({ mask, preModify, postModify }) => {
  const applyMask = mask ? createMask(mask) : null

  return (value) => {
    let newValue = value

    if (typeof preModify === 'function') {
      newValue = preModify(newValue)
    }

    if (typeof applyMask === 'function') {
      newValue = applyMask(newValue)
    }

    if (typeof postModify === 'function') {
      newValue = postModify(newValue)
    }

    return newValue
  }
}

// Mask result - mm/dd/yyyy
const initDateMask = (): Mask => {
  const preModify = (value) => value.replace(/\D/g, '')

  const postModify = (value) => {
    let [ month, day, year ] = value.split('/')

    if (month && month > 12) {
      month = 12
    }

    if (day && day > 31) {
      day = 31
    }

    return [ month, day, year ].filter(Boolean).join('/')
  }

  return createMaskWithModifiers({
    mask: 'XX/XX/XXXX',
    preModify,
    postModify,
  })
}

const initUSPhoneMask = (): Mask => {
  const preModify = (value: string) => value.replace(/\D/g, '')

  return createMaskWithModifiers({
    preModify,
    mask: '+1 XXX XXX XX XX',
  })
}

const initInternationalPhoneMask = (): Mask => {
  const preModify = (value: string) => value.replace(/\D/g, '')

  const maskFor11 = createMask('+X XXX XXX XX XX')
  const maskFor12 = createMask('+XX XXX XXX XXXX')
  const maskForRest = createMask('+XXX XXX XXX XXXX XX')

  return (value: string) => {
    // keep only digits
    let newValue = preModify(value)

    // north american phone number, just in case
    if (newValue[0] === '1') {
      return maskFor11(newValue)
    }

    // special case for UK phone number
    if (newValue.startsWith('44')) {
      return maskFor12(newValue)
    }

    // something like european phone number
    if (newValue.length < 12) {
      return maskFor12(newValue)
    }

    // any possible phone number up to 15 digits
    return maskForRest(newValue)
  }
}


export const masks = {
  date: initDateMask(),
  usPhone: initUSPhoneMask(),
  phone: initInternationalPhoneMask(),
}
