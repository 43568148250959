const getTestIdFromText = (...messages: Array<string | Intl.Message>): string => {
  return messages.reduce((acc: string, message, messageIndex) => {
    const inputText = typeof message === 'string' ? message : message.en

    const formattedText = inputText.replace(/(?:^\w|[A-Z]|[-_]\w|\b\w|\s+\w)/g, (match, matchIndex) =>
      !matchIndex && !messageIndex ? match.toLowerCase() : match.replace(/[-_\s]/, '').toUpperCase()
    )

    return acc + formattedText
  }, '') as string
}

export default getTestIdFromText
